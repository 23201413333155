import React, { useEffect, useState } from 'react';
import { DndProvider, } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Alert, AlertTitle, IconButton, Typography } from '@mui/material';
import HomeBar from '../components/HomeBar/HomeBar';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { adicionarHorasUteis } from '../helpers/datehelper';
import Lista from '../components/Funil/Lista';
import { getContatos } from '../services/SrvContato';
import { getListas, initListas } from '../services/SrvLista';
import { getCards, newCard, updCard } from '../services/SrvCard';
import ContactsIcon from '@mui/icons-material/Contacts';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import ContatosList from '../components/ContatosList';
import ProdutoLista from '../components/Produto/ProdutoLista';


const ItemTypes = {
    CARD: 'card',
};


const Funil = () => {

    const [contatoSelecionado, setContatoSelecionado] = useState(null)
    const [contatos, setContatos] = useState(null)
    const [addContatoBtnDisabled, setAddContatoBtnDisabled] = useState(true)
    const [alert, setAlert] = useState({ show: false, severity: '', message: '' });
    const [menuLateral, setMenuLateral] = useState({ funil: true, clientes: false, cardsInativos: false, produtos: false })
    const [listas, setListas] = useState([])

    var autenticado = false
    var chave = null
    var perfil = null
    var nome = null

    const location = useLocation()
    const { state } = location

    if (state) {
        chave = state.token
        perfil = state.profile
        autenticado = state.isAuthenticated
        nome = state.nome
    }

    const handleMenuLateralClick = (opcao) => {
        switch (opcao) {
            case 'funil':
                setMenuLateral({ funil: true, clientes: false, cardsInativos: false, produtos: false })
                break
            case 'clientes':
                setMenuLateral({ funil: false, clientes: true, cardsInativos: false, produtos: false })
                break
            case 'cardsInativos':
                setMenuLateral({ funil: false, clientes: false, cardsInativos: true, produtos: false })
                break
            case 'produtos':
                setMenuLateral({ funil: false, clientes: false, cardsInativos: false, produtos: true })
                break
            default:
                setMenuLateral({ funil: true, clientes: false, cardsInativos: false, produtos: false })
                break
        }
    }

    async function fetchListas() {
        await getListas(chave)
            .then((response) => {
                //console.log('No fetchListas: ', response)
                if (response.length === 0) {  // não há listas criadas ainda para o assinante
                    initializeListas()
                } else {
                    setListas(response)
                }
            },
                (error) => {
                    // console.log('Erro ao recuperar listas: ', error)
                    alert(`Erro ao recuperar listas: ${error}`)
                })
    }

    async function fetchContatos() {
        await getContatos(chave)
            .then((response) => {
                // console.log('response no fetchContatos: ', response)
                setContatos(response)
            },
                (error) => {
                    // console.log('Erro ao recuperar contatos: ', error)
                    alert(`Erro ao recuperar contatos: ${error}`)
                }
            )
    }

    async function initializeListas() {
        await initListas(chave)
            .then((response) => {
                setListas(response)
                setAlert({ show: true, message: 'Funil criado com sucesso!', severity: 'success' })
            },
                (error) => {
                    // console.log('Erro ao inicializar funil', error)
                    setAlert({ show: true, message: `Erro ao criar funil: ${error.response.data}`, severity: 'error' })
                })
    }

    useEffect(() => {
        fetchContatos()
        fetchListas()
        // eslint-disable-next-line
    }, [])

    async function fetchCards() {
        return await getCards(chave)
    }

    const handleAddContatoClick = () => {

        if (contatoSelecionado) {
            fetchCards()
                .then((response) => {
                    const cards = response
                    if (cards.length > 0) {
                        const contatoCards = cards.filter(card => card.contato === contatoSelecionado.id)
                        if (contatoCards.length > 0) {
                            setAlert({ show: true, message: 'O cliente potencial já está no funil', severity: 'warning' })
                        } else {
                            addCard(contatoSelecionado)
                        }
                        // console.log('cards: ', cards)
                    } else {
                        addCard(contatoSelecionado);
                    }
                }, (error) => {
                    // console.log('erro ao recuperar cards: ', error)
                    alert(`Erro ao recuperar cards: ${error}`)
                })
        }
    }

    const handleContatoSelecionado = (item) => {
        setContatoSelecionado(item)
        setAddContatoBtnDisabled(false)
    }

    async function setCard(card) {
        const cardAtualizado = await updCard(chave, card).then(
            (response) => {
                return response
            },
            (erro) => {
                // console.log('Ocorreu um erro ao atualizar o card: ', erro)
                alert(`Ocorreu um erro ao atualizar o card: ${erro}`)
                return null
            }
        )

        return cardAtualizado
    }

    async function addCard(contatoSelecionado, lista, produtoId) {
        const data_atual = new Date();
        const horas_expiracao = lista.horas_expiracao;
        const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
        const dados = {
            'contato': contatoSelecionado.id,
            'lista': lista.id,
            'produto': produtoId,
            'data_lista': new Date(),
            'data_expiracao': data_expiracao,
            'observacoes': null
        };
        console.log('dados no addcard: ', dados)
        await newCard(chave, dados).then(
            (response) => {
                console.log('response no addcard: ', response)
                fetchListas();
                setAlert({ show: true, message: 'Cliente adicionado com sucesso ao funil!', severity: 'success' });
            },
            (error) => {
                setAlert({ show: true, message: `Erro ao adicionar o potencial cliente ao funil: ${error}`, severity: 'error' });
                console.log('deu erro no newcard: ', { error })
            }
        );
    }

    const moveCard = (sourceIndex, sourceListaId, destListaId) => {
        const sourceLista = listas.find((lista) => lista.id === sourceListaId);
        // console.log('sourceLista: ', sourceLista)
        const destLista = listas.find((lista) => lista.id === destListaId);
        // console.log('destLista: ', destLista)

        if (sourceLista && destLista) {
            const [card] = sourceLista.cards.splice(sourceIndex, 1);

            if (card) {
                const data_atual = new Date();
                const horas_expiracao = destLista.horas_expiracao;
                const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);

                card.lista = destListaId
                card.data_lista = data_atual
                card.data_expiracao = data_expiracao
                setCard(card).then(
                    (response) => {
                        fetchListas()
                    }
                )
            }
        }

        // destLista.cards.push(card);
        // setListas([...listas]);
    };

    return (
        <>
            {alert.show && (
                <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })} sx={{ paddingTop: '69px' }}>
                    <AlertTitle>{alert.severity === 'success' ? 'Sucesso' : 'Erro'}</AlertTitle>
                    {alert.message}
                </Alert>
            )}
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', paddingTop: alert.show ? '0px' : '69px' }}>
                <HomeBar nome={perfil.nome} token={chave} profile={perfil} isAuthenticated={autenticado} />
                <Box component='main' display='flex' flexGrow={1}>
                    <Box display='flex' flexDirection='column' sx={{ minWidth: 150, maxWidth: 160, backgroundColor: 'whitesmoke', padding: 2, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        {/* {
                            contatos && (
                                <>
                                    <FunilClienteAutoComplete items={contatos} setItem={handleContatoSelecionado} />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button variant='contained' endIcon={<SendIcon />} sx={{ marginTop: 1 }} size='small' disabled={addContatoBtnDisabled} onClick={handleAddContatoClick}>
                                            Adicionar ao funil
                                        </Button>
                                    </Box>
                                </>
                            )
                        }

                        <ContatoForm 
                            chave={chave} 
                            perfil={perfil} 
                            setAlert={setAlert} 
                            fetchListas={fetchListas} 
                            fetchContatos={fetchContatos} 
                            addCard={addCard} 
                        /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: 2
                            }}
                        >
                            <IconButton
                                sx={{
                                    width: 80,
                                    height: 80,
                                    color: '#5c00cd',
                                    backgroundColor: '#f0e6fb',
                                    border: menuLateral.funil ? 'solid 1px' : 'none'
                                }}
                                onClick={() => { handleMenuLateralClick('funil') }}
                            >
                                <FilterAltIcon
                                    sx={{
                                        width: 60,
                                        height: 60
                                    }}
                                />
                            </IconButton>
                            <Typography variant='caption' sx={{ marginTop: 1 }}>
                                Funil
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: 2
                            }}
                        >
                            <IconButton
                                sx={{
                                    width: 80,
                                    height: 80,
                                    color: '#5c00cd',
                                    backgroundColor: '#f0e6fb',
                                    border: menuLateral.clientes ? 'solid 1px' : 'none'
                                }}
                                onClick={() => { handleMenuLateralClick('clientes') }}
                            >
                                <ContactsIcon
                                    sx={{
                                        width: 60,
                                        height: 60
                                    }}
                                />
                            </IconButton>
                            <Typography variant='caption' sx={{ marginTop: 1 }}>
                                Potenciais Clientes
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: 2
                            }}
                        >
                            <IconButton
                                sx={{
                                    width: 80,
                                    height: 80,
                                    color: '#5c00cd',
                                    backgroundColor: '#f0e6fb',
                                    border: menuLateral.cardsInativos ? 'solid 1px' : 'none'
                                }}
                                onClick={() => { handleMenuLateralClick('cardsInativos') }}
                            >
                                <CreditCardOffIcon
                                    sx={{
                                        width: 60,
                                        height: 60
                                    }}
                                />
                            </IconButton>
                            <Typography variant='caption' sx={{ marginTop: 1 }}>
                                Cards Inativos
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: 2
                            }}
                        >
                            <IconButton
                                sx={{
                                    width: 80,
                                    height: 80,
                                    color: '#5c00cd',
                                    backgroundColor: '#f0e6fb',
                                    border: menuLateral.produtos ? 'solid 1px' : 'none'
                                }}
                                onClick={() => { handleMenuLateralClick('produtos') }}
                            >
                                <FontAwesomeIcon
                                    size='2x'
                                    icon={faProductHunt}
                                />
                            </IconButton>
                            <Typography variant='caption' sx={{ marginTop: 1 }}>
                                Produtos
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Box sx={{ backgroundColor: '#1D7AFC', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', width: '100%' }}> */}


                    {
                        menuLateral.funil && (
                            <Box sx={{ backgroundColor: '#f0e6fb', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <DndProvider backend={HTML5Backend}>

                                    {
                                        listas.sort((a, b) => a.ordem - b.ordem).map((lista) => (
                                            <Lista key={lista.id} lista={lista} moveCard={moveCard} token={chave} perfil={perfil} setAlert={setAlert} fetchListas={fetchListas} statusCards={'A'} addCard={addCard} />
                                        ))
                                    }

                                </DndProvider>
                            </Box>
                        )
                    }


                    {
                        menuLateral.clientes && (
                            <Box flexGrow={1} p={2} sx={{ backgroundColor: '#f0e6fb', height: "100vh" }}>
                                <ContatosList nome={nome} token={chave} profile={perfil} isAuthenticated={autenticado} />
                            </Box>
                        )
                    }

                    {
                        menuLateral.cardsInativos && (
                            <Box sx={{ backgroundColor: '#f0e6fb', display: 'flex', flexDirection: 'row', flexGrow: 1, height: "100vh", justifyContent: 'space-around' }}>
                                <DndProvider backend={HTML5Backend}>

                                    {
                                        listas.sort((a, b) => a.ordem - b.ordem).map((lista) => (
                                            <Lista key={lista.id} lista={lista} moveCard={moveCard} token={chave} perfil={perfil} setAlert={setAlert} fetchListas={fetchListas} statusCards={'I'} addCard={addCard} />
                                        ))
                                    }

                                </DndProvider>
                            </Box>
                        )
                    }

                    {
                        menuLateral.produtos && (
                            <ProdutoLista perfil={perfil} chave={chave}/>
                        )
                    };
                </Box >
            </Box >
        </>
    )
}

export { Funil, ItemTypes };
