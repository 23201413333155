import { Box, Fab, List, Paper, Typography } from "@mui/material";
import Cartao from "./Cartao";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../routes/Funil";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import ContatoForm from "./ContatoForm";

// lista={lista} moveCard={moveCard} token={chave} perfil={perfil} setAlert={setAlert} fetchListas={fetchListas} fetchContatos={fetchContatos} addCard={addCard}
// const Lista = ({ lista, moveCard, token, perfil, setAlert, fetchListas, fetchContatos, addCard }) => {
const Lista = ({ lista, moveCard, token, perfil, setAlert, fetchListas, statusCards, addCard }) => {
    const [formVisible, setFormVisible] = useState(false)
    const permite_entrada = lista.permite_entrada

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item) {
            if (item.lista.id !== lista.id) {
                moveCard(item.index, item.lista.id, lista.id);
                item.index = lista.cards.length; // Move to the end of the new column
                item.lista = lista;
            }
        },
    });

    const handleAddCardClick = () => {
        setFormVisible(!formVisible)
    }

    return (
        // '#D3D3D3'
        <Box sx={{ margin: '2px', padding: '2px' }}>
            {
                permite_entrada && (
                    <Box sx={{ textAlign: "center" }}>
                        <Fab size="small" color="secondary" aria-label="adicionar" onClick={handleAddCardClick}>
                            <AddIcon />
                        </Fab>
                        {
                            formVisible && (
                                <ContatoForm 
                                    chave={token} 
                                    perfil={perfil} 
                                    lista={lista}
                                    setAlert={setAlert} 
                                    fetchListas={fetchListas} 
                                    // fetchContatos={fetchContatos} 
                                    addCard={addCard} 
                                    setFormVisible={setFormVisible} />
                            )
                        }
                    </Box>
                )
            }
            <Typography variant="h6" sx={{ fontFamily: '"Helvetica Neue", Arial, sans-serif', textAlign: 'center' }} >{lista.nome}</Typography>
            <Paper ref={drop} sx={{ margin: '16px', padding: '16px', minWidth: '260px', backgroundColor: '#f8f8f8', borderRadius: '5px', maxHeight: 765, overflow: 'auto' }} elevation={3}>

                <List>
                    {
                        lista.cards ? (
                            lista.cards.filter(card => card.status === statusCards).sort((a, b) => b.rating - a.rating).map((card, index) => (
                                <Cartao
                                    key={card.id}
                                    cartao={card}
                                    index={index}
                                    moveCard={moveCard}
                                    lista={lista}
                                    token={token}
                                    fetchListas={fetchListas}
                                />
                            ))
                        ) : (
                            <></>
                        )
                    }
                </List>
            </Paper>
        </Box>

    );
};

export default Lista;